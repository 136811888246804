import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NationalPortalCompanyName } from '@app/config';
import { Store } from '@ngxs/store';
import { SettingsActions } from 'src/app/settings/store/settings.actions';

@Injectable({
  providedIn: 'root',
})
export class AppDownloadGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): boolean {
    this.store.dispatch(new SettingsActions.GetPortalSettingByCompany(NationalPortalCompanyName));

    return true;
  }
}
