import { LanguageObject } from '@adstate_as/flora/lib/interfaces/language-switcher.interface';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CookieSettings,
  LanguageOptions,
  NationalPortalCompanyName,
  SnippetAttribute,
  TranslationLoadTypes,
  cookiePopUpCurrentVersionCookieName,
  cookiePopUpCurrentVersion,
  ThemeColorTypes,
} from '@app/config';
import { CookieService } from '@app/core/services/cookie.service';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { SettingsActions } from './store/settings.actions';
import { SettingsSelectors } from './store/settings.selectors';
import { SiteSettingsInterface } from './store/settings.state';
import { environment } from '@app/env';
import { ApplicationSelectors } from '../store/application.selectors';
import { ApplicationStateModel } from '../store/application.state';
import { ApplicationActions } from '../store/application.actions';
import { filter } from 'rxjs/operators';
import { CookieCategory } from '@adstate_as/flora/lib/interfaces/cookie-pop-up';
import { ThemeService } from '@app/core/services/theme.service';
import { TrackingService } from './services/tracking.service';

@Component({
  selector: 'adstate-settings-component',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() company = NationalPortalCompanyName;
  @Input() language: LanguageObject['iso'] = 'en_GB';
  @Output() changeLanguage = new EventEmitter();
  @Output() cookiePopUpEventListenerStarted = new EventEmitter();

  @Select(SettingsSelectors.cookies) cookies$: Observable<CookieCategory[]>;
  @Select(SettingsSelectors.siteSettings) siteSettings$: Observable<SiteSettingsInterface>;
  @Select(SettingsSelectors.slugSettings) slugSettings$: Observable<any>;
  @Select(SettingsSelectors.cookiePopUp) showCookiePopUp$: Observable<boolean>;
  @Select(SettingsSelectors.settingsModal) showSettingsModal$: Observable<boolean>;
  @Select(SettingsSelectors.siteLanguageSettings) siteLanguageSettings$: Observable<SiteSettingsInterface['siteLanguage']>;
  @Select(ApplicationSelectors.readyLanguages) readyLanguages$: Observable<ApplicationStateModel['readyLanguages']>;
  @Select(ApplicationSelectors.currentReadyLanguage) currentReadyLanguage$: Observable<ApplicationStateModel['currentReadyLanguage']>;

  currentTheme: string;
  themePrimaryColor: string;
  showCookiePopUp = true;
  showSettingsModal = false;
  initialSettingsModalPage = 'cookies';
  getSettingsForCompany = !environment.exportSettingsComponent;
  useAppWrapper = environment.exportSettingsComponent;
  LanguageOptions = LanguageOptions;
  TranslationLoadTypes = TranslationLoadTypes;
  SnippetAttribute = SnippetAttribute;
  currentReadyLanguageSubscription: Subscription = null;
  applicationIsEmbedded = environment.exportPortalComponent;

  private debouncedCheckIfAllCurrentCookieCategoriesAreAccepted: (cookieCategories: CookieCategory[]) => boolean;

  constructor(
    private elementRef: ElementRef,
    private store: Store,
    private cookieService: CookieService,
    private translateService: TranslocoService,
    private themeService: ThemeService,
    private trackingService: TrackingService
  ) {
    // Setup initial open/close state of cookie pop up
    this.store.dispatch(new SettingsActions.GetCookiePopUp());

    this.cookieService.changes.subscribe((change) => {
      if (change.key === CookieSettings.COOKIEPOPUP) {
        this.store.dispatch(new SettingsActions.GetCookiePopUp());
      }
    });

    this.slugSettings$.subscribe((slugSettings) => {
      this.company = slugSettings.value ?? this.company;
    });

    this.siteLanguageSettings$.subscribe((siteLanguageSettings) => {
      if (!siteLanguageSettings.value) {
        this.language = LanguageOptions.EN_GB;
      } else {
        this.language = siteLanguageSettings.value;
      }

      this.changeLanguage.emit(this.language);
    });

    if (!this.company) {
      /*
      We only want to get default site settings when the slug is null.
      If it's not null, we must have already set the site settings somewhere.
      For example: via element attributes
      */
      this.store.dispatch(new SettingsActions.GetDefaultSiteSettings());
    }

    // If the visitor has already made a choice for the cookie pop-up, send the choice to the tracking service
    this.showCookiePopUp$.subscribe((showCookiePopUp) => {
      if (showCookiePopUp === false) {
        this.debouncedCheckIfAllCurrentCookieCategoriesAreAccepted = this.debounce(
          (cookieCategories: CookieCategory[]) => this.checkIfAllCurrentCookieCategoriesAreAccepted(cookieCategories),
          1000,
          false
        );
        this.cookies$
          .subscribe((cookieCategories) => {
            // We need to debounce because the cookie categories are updated many times during initialisation
            this.debouncedCheckIfAllCurrentCookieCategoriesAreAccepted(cookieCategories);
          })
          .unsubscribe();
      }
    });

    this.runCookiePopUpVersionCheckAndUpdates();
  }

  ngOnInit(): void {
    this.setupLanguageSwitching();
    this.setupAttributes();
    this.setupTheme();
  }

  ngOnDestroy(): void {
    if (this.currentReadyLanguageSubscription) {
      this.currentReadyLanguageSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.language && changes.language.previousValue !== changes.language.currentValue) {
      this.language = changes.language.currentValue;
      this.store.dispatch(new SettingsActions.SetSiteLanguageSettings(this.language));
    }

    if (changes?.company && changes.company.previousValue !== changes.company.currentValue) {
      this.company = changes.company.currentValue;
      if (this.getSettingsForCompany) {
        this.store.dispatch(new SettingsActions.GetPortalSettingByCompany(this.company));
      }
    }
  }

  setupAttributes(): void {
    const languageAttribute = this.elementRef.nativeElement.getAttribute(SnippetAttribute.Language);
    this.themePrimaryColor = this.elementRef.nativeElement.getAttribute(SnippetAttribute.ThemeColor);

    if (languageAttribute) {
      this.language = languageAttribute;
      this.store.dispatch(new SettingsActions.SetSiteLanguageSettings(this.language));
    }

    this.siteSettings$.pipe(filter((siteSetting) => !!siteSetting)).subscribe((siteSetting: SiteSettingsInterface) => {
      if (this.getSettingsForCompany && languageAttribute && siteSetting.siteLanguage.value !== languageAttribute) {
        this.store.dispatch(new SettingsActions.GetPortalSettingByCompany(this.company));
      }
    });
  }

  setupLanguageSwitching(): void {
    this.translateService.events$.subscribe((event) => {
      if (event.type === TranslationLoadTypes.TranslationLoadSuccess) {
        this.store.dispatch(new ApplicationActions.AddReadyLanguage(event.payload.langName));
        this.store.dispatch(new ApplicationActions.SetCurrentReadyLanguage(event.payload.langName));
      }
    });

    this.translateService.langChanges$.subscribe((newLanguage) => {
      this.readyLanguages$.forEach((readyLanguage) => {
        if (readyLanguage && readyLanguage.has(newLanguage)) {
          this.store.dispatch(new ApplicationActions.SetCurrentReadyLanguage(newLanguage));
        }
      });
    });

    this.currentReadyLanguageSubscription = this.currentReadyLanguage$.subscribe((currentReadyLanguage) => {
      if (currentReadyLanguage) {
        this.store.dispatch(new SettingsActions.GetSavedCookies(currentReadyLanguage));
      }
    });
  }

  openSettingsModal(page): void {
    this.store.dispatch(new SettingsActions.SetSettingsModal(true));
  }

  /** Responsible for handling updates to the persisted state from the settings-component/cookie-pop-up via Semantic versioning
   * - MAJOR version change: Delete all current cookies, but make sure necessary are applied and that pop-up is shown.
   * - MINOR version change: Save current on/off state, delete all, re-apply on/off state, make sure pop-up remains hidden.
   * - PATCH version change: Same as MINOR, only for following semantic versioning.
   */
  runCookiePopUpVersionCheckAndUpdates(): void {
    const versionSavedInCookie = this.cookieService.get(cookiePopUpCurrentVersionCookieName, null);

    if (!versionSavedInCookie || versionSavedInCookie.major < cookiePopUpCurrentVersion.major) {
      this.cookieService.deleteAll();
      this.store.dispatch(
        new SettingsActions.UpdateCookieCategory({ category: 'necessary', newValue: { checked: true, indeterminate: false } })
      );
      this.store.dispatch(new SettingsActions.SetCookiePopUp(true));
      this.store.dispatch(new SettingsActions.SaveCookiePopUp());
      this.store.dispatch(new SettingsActions.SaveCookiesState());
    } else if (
      versionSavedInCookie.minor < cookiePopUpCurrentVersion.minor ||
      versionSavedInCookie.patch < cookiePopUpCurrentVersion.patch
    ) {
      const oldCookiesState = [];
      this.cookies$
        .subscribe((cookies): void => {
          cookies.forEach((cookieCategory) => {
            cookieCategory.cookies.forEach((cookie) => {
              oldCookiesState.push({
                category: cookieCategory.slug,
                cookie: cookieCategory.slug,
                newValue: {
                  checked: cookie.checked,
                  indeterminate: cookie.indeterminate,
                },
              });
            });
          });
        })
        .unsubscribe();

      this.cookieService.deleteAll();
      this.store.dispatch(new SettingsActions.SetCookiePopUp(false));
      this.store.dispatch(new SettingsActions.SaveCookiePopUp());

      oldCookiesState.forEach((cookie) => {
        this.store.dispatch(new SettingsActions.UpdateCookie(cookie));
      });

      this.store.dispatch(new SettingsActions.SaveCookiesState());
      this.cookieService.set(cookiePopUpCurrentVersionCookieName, cookiePopUpCurrentVersion);
    }
  }

  setupTheme(): void {
    if (this.themePrimaryColor) {
      const themeColors = [
        {
          type: ThemeColorTypes.PRIMARY,
          baseColor: this.themePrimaryColor,
          palette: {
            50: this.themeService.hexToHSL(this.themePrimaryColor, 95),
            100: this.themeService.hexToHSL(this.themePrimaryColor, 90),
            200: this.themeService.hexToHSL(this.themePrimaryColor, 80),
            300: this.themeService.hexToHSL(this.themePrimaryColor, 70),
            400: this.themeService.hexToHSL(this.themePrimaryColor, 60),
            500: this.themePrimaryColor,
            600: this.themeService.hexToHSL(this.themePrimaryColor, 40),
            700: this.themeService.hexToHSL(this.themePrimaryColor, 30),
            800: this.themeService.hexToHSL(this.themePrimaryColor, 20),
            900: this.themeService.hexToHSL(this.themePrimaryColor, 10),
            'contrast-unsaturated-50': this.themeService.hexToHSL(this.themePrimaryColor, 5),
            'contrast-unsaturated-100': this.themeService.hexToHSL(this.themePrimaryColor, 10),
            'contrast-unsaturated-200': this.themeService.hexToHSL(this.themePrimaryColor, 20),
            'contrast-unsaturated-300': this.themeService.hexToHSL(this.themePrimaryColor, 30),
            'contrast-unsaturated-400': this.themeService.hexToHSL(this.themePrimaryColor, 40),
            'contrast-unsaturated-500': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-unsaturated-600': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-unsaturated-700': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-unsaturated-800': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-unsaturated-900': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-50': this.themeService.hexToHSL(this.themePrimaryColor, 5),
            'contrast-saturated-100': this.themeService.hexToHSL(this.themePrimaryColor, 10),
            'contrast-saturated-200': this.themeService.hexToHSL(this.themePrimaryColor, 20),
            'contrast-saturated-300': this.themeService.hexToHSL(this.themePrimaryColor, 30),
            'contrast-saturated-400': this.themeService.hexToHSL(this.themePrimaryColor, 40),
            'contrast-saturated-500': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-600': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-700': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-800': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-900': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'a-100': this.themeService.hexToHSL(this.themePrimaryColor, 80),
            'a-200': this.themeService.hexToHSL(this.themePrimaryColor, 60),
            'a-400': this.themeService.hexToHSL(this.themePrimaryColor, 40),
            'a-700': this.themeService.hexToHSL(this.themePrimaryColor, 20),
            'contrast-saturated-a-100': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-a-200': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-a-400': this.themeService.hexToHSL(this.themePrimaryColor, 99),
            'contrast-saturated-a-700': this.themeService.hexToHSL(this.themePrimaryColor, 99),
          },
        },
      ];
      this.themeService.applyColors(themeColors);
    }
  }

  private checkIfAllCurrentCookieCategoriesAreAccepted(cookieCategories: CookieCategory[]): boolean {
    const checkedCookieCategories = cookieCategories.filter((cookieCategory) => cookieCategory.checked);
    if (checkedCookieCategories.length === cookieCategories.length) {
      this.trackingService.onTrackCookiePopupAcceptAll();
    } else {
      this.trackingService.onTrackCookiePopupAcceptOnlyNecessary();
    }

    return checkedCookieCategories.length === cookieCategories.length;
  }

  private debounce = (callback: (args: any) => any, wait: number, immediate: boolean): (() => any) => {
    let timeout;
    return function (): void {
      const context = this;
      const args = arguments;
      const later = (): void => {
        timeout = null;
        if (!immediate) {
          callback.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        callback.apply(context, args);
      }
    };
  };
}
