import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@app/env';
import { DeviceDetectorService, DeviceInfo, DeviceType, OS } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { TrackingService } from 'src/app/settings/services/tracking.service';
import { CustomPageService } from '../../services/custom-page.service';
import { SeoService } from '@app/core/services/seo.service';

const iOSReviews = {
  ratingScore: 4.8,
  ratingCount: 8,
};

const androidReviews = {
  ratingScore: 4.4,
  ratingCount: 8,
};

const weightedReviews = {
  ratingScore:
    (iOSReviews.ratingScore * iOSReviews.ratingCount + androidReviews.ratingScore * androidReviews.ratingCount) /
    (iOSReviews.ratingCount + androidReviews.ratingCount),
  ratingCount: iOSReviews.ratingCount + androidReviews.ratingCount,
};

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements OnInit {
  translations$ = new BehaviorSubject({
    title: null,
    content: null,
    loading: false,
    error: false,
  });

  userDevice = this.detectDevice();
  applicationIsEmbedded = environment.exportPortalComponent;
  iOSAppStoreLink = 'https://apps.apple.com/no/app/minnesider/id1529239925';
  AndroidGooglePlayStoreLink = 'https://play.google.com/store/apps/details?id=com.adstate.admemoria';
  seoTitle = 'Minnesider Appen';
  seoDescription =
    'Med denne appen kan du søke opp dødsannonser og minnesider i hele Norge. Her kan du få praktisk informasjon om seremonien, bestille blomster, tenne lys, skrive minneord og mye mer.';
  seoImage = `${environment.hostLink}/images/three-phones.png`;

  constructor(
    private route: ActivatedRoute,
    private customPageService: CustomPageService,
    private deviceService: DeviceDetectorService,
    private trackingService: TrackingService,
    private seoService: SeoService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.loadPageData();
    this.redirectMobileDeviceToAppStore();
    this.setupSEO();
  }

  setupSEO(): void {
    this.seoService.setTitleTag(this.seoTitle);
    this.seoService.setMetaTag({ property: 'og:title', content: this.seoTitle });
    this.seoService.setMetaTag({ name: 'description', content: this.seoDescription });
    this.seoService.setMetaTag({ property: 'og:description', content: this.seoDescription });
    this.seoService.setMetaTag({ property: 'og:image', content: this.seoImage });

    this.seoService.setLinkTag({
      id: 'seo-canonical-tag',
      rel: 'canonical',
      href: `${environment.hostLink}/app`,
    });

    const script = this.document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      applicationCategory: 'News',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: weightedReviews.ratingScore.toFixed(1),
        ratingCount: weightedReviews.ratingCount,
      },
      description: this.seoDescription,
      name: this.seoTitle,
      image: this.seoImage,
      offers: [
        {
          '@type': 'Offer',
          price: '0',
          priceCurrency: 'USD',
          availability: 'https://schema.org/InStock',
          url: this.iOSAppStoreLink,
          itemOffered: {
            '@type': 'SoftwareApplication',
            operatingSystem: 'iOS',
          },
        },
        {
          '@type': 'Offer',
          price: '0',
          priceCurrency: 'USD',
          availability: 'https://schema.org/InStock',
          url: this.AndroidGooglePlayStoreLink,
          itemOffered: {
            '@type': 'SoftwareApplication',
            operatingSystem: 'Android',
          },
        },
      ],
    });
    this.document.head.appendChild(script);
  }

  detectDevice(): DeviceInfo | undefined {
    const userAgent = window.navigator.userAgent;
    this.deviceService.setDeviceInfo(userAgent);
    return this.deviceService.getDeviceInfo();
  }

  redirectMobileDeviceToAppStore(): void {
    let redirectToAppStore = false;
    let redirectLink = '';

    this.route.queryParams.subscribe((params) => {
      redirectToAppStore = this.userDevice && this.userDevice.deviceType === DeviceType.Mobile;

      // Don't redirect if they have param: "redirect=false"
      if (Object.keys(params).length !== 0 && params.redirect && params.redirect === 'false') {
        return;
      }

      if (this.userDevice.os === OS.IOS) {
        redirectLink = this.iOSAppStoreLink;
        this.trackingService.onTrackDownloadAppPageRedirectForIOS();
      } else if (this.userDevice.os === OS.ANDROID) {
        redirectLink = this.AndroidGooglePlayStoreLink;
        this.trackingService.onTrackDownloadAppPageRedirectForAndroid();
      }

      if (redirectToAppStore && redirectLink.length !== 0 && redirectLink.includes('http')) {
        this.document.location.href = redirectLink;
      }
    });
  }

  loadPageData = (): void => {
    this.translations$.next({
      title: null,
      content: null,
      loading: true,
      error: false,
    });

    try {
      this.customPageService.getPageById('1').subscribe((value) => {
        this.translations$.next(value);
      });
    } catch (error) {
      console.error(error);
      this.translations$.next({
        title: null,
        content: null,
        loading: false,
        error: true,
      });
    }
  };
}
