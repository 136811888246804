import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ColorTypes, ThemeColorTypes } from 'src/app/app.config';
import { SettingsActions } from 'src/app/settings/store/settings.actions';
import { CustomerSiteServiceSettingsResponse } from '../interface/customer-service.interface';
import { Theme } from '../interface/theme.interface';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private store: Store) {}

  apply(companySlug: string, customerSiteSettings: CustomerSiteServiceSettingsResponse): void {
    this.applyTheme(companySlug);
    this.applyColors(customerSiteSettings.themes[0].colors);
    this.applyMedia(customerSiteSettings.themes[0]);
    this.applyLinks(customerSiteSettings.portalSettings);
    this.applyLanguage(customerSiteSettings.portalSettings);
  }

  applyTheme(companySlug: string): void {
    this.store.dispatch(new SettingsActions.SetSiteThemeSetting({ value: companySlug }));
  }

  applyColors(themeColors: Theme['colors']): void {
    themeColors
      .filter((themeColor) => themeColor.type === ThemeColorTypes.PRIMARY)
      .forEach((themeColor) => {
        for (const key in themeColor.palette) {
          if (Object.prototype.hasOwnProperty.call(themeColor.palette, key)) {
            const value = themeColor.palette[key];
            document.documentElement.style.setProperty(ColorTypes.PRIMARY + key, value);
          }
        }
      });
  }

  applyMedia(theme): void {
    this.store.dispatch(
      new SettingsActions.SetSiteThemeMediaSetting({
        logo: theme.logo,
        banner: theme.banner,
      })
    );
  }

  applyLinks(portalSettings): void {
    this.store.dispatch(
      new SettingsActions.SetSiteLinksSettings({
        homePage: portalSettings.homePage,
        loginUrl: portalSettings.loginUrl,
      })
    );
  }

  applyLanguage(portalSettings): void {
    this.store.dispatch(new SettingsActions.SetSiteLanguageSettings(portalSettings.language));
  }

  hexToHSL(hex: string, lightness: number): string {
    const matches = hex.substring(1).match(/.{2}/g);
    if (matches === null) {
      throw new Error('Invalid hex format');
    }
    const rgb = matches.map((color) => parseInt(color, 16) / 255);
    const max = Math.max(...rgb);
    const min = Math.min(...rgb);
    const chroma = max - min;
    const hue =
      chroma === 0
        ? 0
        : 60 *
          ((max === rgb[0]
            ? (rgb[1] - rgb[2]) / chroma
            : max === rgb[1]
            ? 2 + (rgb[2] - rgb[0]) / chroma
            : 4 + (rgb[0] - rgb[1]) / chroma) %
            6);
    const saturation = chroma === 0 ? 0 : chroma / (1 - Math.abs(2 * (lightness / 100) - 1));

    return `hsl(${Math.round(hue)}, ${Math.round(saturation * 100)}%, ${Math.round(lightness)}%)`;
  }
}
