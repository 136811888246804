import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donationinfo-page',
  templateUrl: './donationinfo.component.html',
  styleUrls: ['./donationinfo.component.scss'],
})
export class DonationinfoPageComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
