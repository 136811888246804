export const NationalPortalCompanyName = 'minnesider';

export enum LanguageOptions {
  EN_GB = 'en_GB',
  NB_NO = 'nb_NO',
  SV_SE = 'sv_SE',
  DA_DK = 'da_DK',
  NL_NL = 'nl_NL',
}

export enum ThemeColorTypes {
  PRIMARY = 'PRIMARY',
}

export enum ImageAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum ColorTypes {
  PRIMARY = '--color-primary-',
}

export enum CookieSettings {
  COOKIEPOPUP = 'settings-cookie-pop-up',
}

export enum TagName {
  AdstatePortal = 'adstate-portal',
  SettingsComponent = 'adstate-settings-component',
  AppRoot = 'app-root',
}

export enum SnippetAttribute {
  Company = 'company',
  DisplayLanguageSwitcher = 'displayLanguageSwitcher',
  UseCookiePopUp = 'useCookiePopUp',
  DisplayBanner = 'displayBanner',
  DisplayMenu = 'displayMenu',
  DisplayLogo = 'displayLogo',
  Language = 'language',
  ThemeColor = 'color',
}

export enum TranslationLoadTypes {
  TranslationLoadSuccess = 'translationLoadSuccess',
}

export const paginatorResultsPerPageOptions: number[] = [5, 25, 50, 100, 200];

export enum TrackingEnum {
  TrackingEventNameCustom = 'trackEvent',
  TrackingEventNameSiteSearch = 'trackSiteSearch',
  CookiePopup = 'Cookie pop-up',
  SettingModal = 'Setting modal',
  DownloadAppPage = 'App download page',
  Redirect = 'Redirect',
  Click = 'Click',
  SeeDetail = 'See details',
  AcceptSelected = 'Accept selected',
  AcceptOnlyNecessary = 'Accept only necessary',
  AcceptAll = 'Accept all',
  IOS = 'iOS',
  Android = 'Android',
  TrackingSearchDefaultOrEmpty = 'open result in initial result',
  TrackingSearchIsOnlyFilteredBeforeOpening = 'open result after filter',
  TrackTheLinkIsOpenedByASpecificSearch = 'open result after search term',
  TrackTheSpecificSearchIsFilteredBeforeOpening = 'open result after search term and filter',
}

export const cookiePopUpCurrentVersionCookieName = 'version-cookies';
/** Version number for cookie pop-up. If version number changes, the following happens:
 * - MAJOR version change: Delete all current cookies, but make sure necessary are applied and that pop-up is shown.
 * - MINOR version change: Save current on/off state, delete all, re-apply on/off state, make sure pop-up remains hidden.
 * - PATCH version change: Same as MINOR, only for following semantic versioning.
 */
export const cookiePopUpCurrentVersion = {
  major: 2,
  minor: 1,
  patch: 0,
};
