import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationSelectors } from './store/application.selectors';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { TrackingService } from './settings/services/tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(ApplicationSelectors.notFound) notFound$: Observable<boolean>;
  routeChangeCount = 0;

  constructor(private titleService: Title, private router: Router, private trackingSerivce: TrackingService) {
    this.setupPageChangeTracking();
  }

  ngOnInit(): void {}

  private setupPageChangeTracking(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(async (event: NavigationEnd) => {
      if (this.routeChangeCount > 0) {
        const url = event.urlAfterRedirects;
        const title = await new Promise<string>((resolve) => setTimeout(() => resolve(this.titleService.getTitle())));
        this.trackingSerivce.trackPageView(url, title);
      }
      this.routeChangeCount++;
    });
  }
}
